// Generated by Framer (4c8d0fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {MtP4J3dHB: {hover: true}};

const cycleOrder = ["MtP4J3dHB", "cG6Iq9QdB"];

const serializationHash = "framer-5Hpo5"

const variantClassNames = {cG6Iq9QdB: "framer-v-1xx55b4", MtP4J3dHB: "framer-v-nuzm9k"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {active: "cG6Iq9QdB", initial: "MtP4J3dHB"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MtP4J3dHB", xSsYacBwg: title ?? props.xSsYacBwg ?? "Flexible Integration"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, xSsYacBwg, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MtP4J3dHB", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-nuzm9k", className, classNames)} data-framer-name={"initial"} layoutDependency={layoutDependency} layoutId={"MtP4J3dHB"} ref={ref ?? ref1} style={{backgroundColor: "rgba(3, 155, 234, 0)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} variants={{"MtP4J3dHB-hover": {backgroundColor: "rgba(3, 155, 234, 0.05)"}, cG6Iq9QdB: {backgroundColor: "rgba(3, 155, 234, 0.1)"}}} {...addPropertyOverrides({"MtP4J3dHB-hover": {"data-framer-name": undefined}, cG6Iq9QdB: {"data-framer-name": "active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UnViaWstcmVndWxhcg==", "--framer-font-family": "\"Rubik\", \"Rubik Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgba(0, 0, 0, 0.8))"}}>Flexible Integration</motion.p></React.Fragment>} className={"framer-1ey9qmj"} data-framer-name={"Flexible Integration"} fonts={["GF;Rubik-regular"]} layoutDependency={layoutDependency} layoutId={"OgCXV3nZW"} style={{"--extracted-r6o4lv": "rgba(0, 0, 0, 0.8)", "--framer-paragraph-spacing": "0px"}} text={xSsYacBwg} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({cG6Iq9QdB: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7UnViaWstcmVndWxhcg==", "--framer-font-family": "\"Rubik\", \"Rubik Placeholder\", sans-serif"}}>Flexible Integration</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5Hpo5.framer-omuchd, .framer-5Hpo5 .framer-omuchd { display: block; }", ".framer-5Hpo5.framer-nuzm9k { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 7px 12px 7px 12px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-5Hpo5 .framer-1ey9qmj { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5Hpo5.framer-nuzm9k { gap: 0px; } .framer-5Hpo5.framer-nuzm9k > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5Hpo5.framer-nuzm9k > :first-child { margin-left: 0px; } .framer-5Hpo5.framer-nuzm9k > :last-child { margin-right: 0px; } }", ".framer-5Hpo5.framer-v-1xx55b4.framer-nuzm9k { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 33
 * @framerIntrinsicWidth 167
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"cG6Iq9QdB":{"layout":["auto","auto"]},"l589H0bru":{"layout":["auto","auto"]}}}
 * @framerVariables {"xSsYacBwg":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfBM8jsP0t: React.ComponentType<Props> = withCSS(Component, css, "framer-5Hpo5") as typeof Component;
export default FramerfBM8jsP0t;

FramerfBM8jsP0t.displayName = "selector-link";

FramerfBM8jsP0t.defaultProps = {height: 33, width: 167};

addPropertyControls(FramerfBM8jsP0t, {variant: {options: ["MtP4J3dHB", "cG6Iq9QdB"], optionTitles: ["initial", "active"], title: "Variant", type: ControlType.Enum}, xSsYacBwg: {defaultValue: "Flexible Integration", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerfBM8jsP0t, [{explicitInter: true, fonts: [{family: "Rubik", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1VU80V4bVkA.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})